<template>
  <div class="login-wrap">
    <el-col :span="12" style="height: 100%"></el-col>
    <el-col :span="12" style="height: 100%">
      <el-row style="height: 20%"></el-row>
      <el-row style="height: 5%">
        <div style="justify-content: center; display: flex">
          <img src="../assets/images/head.png" />
        </div>
      </el-row>
      <el-row style="height: 50%; width: 80%">
        <div class="loginDiv">
          <el-row style="height: 25%"></el-row>
          <el-row style="height: 15%"><el-input prefix-icon="el-icon-s-custom" placeholder="请输入用户名"
              v-model="loginForm.username"></el-input> </el-row><el-row style="height: 15%"><el-input
              prefix-icon="el-icon-lock" type="password" placeholder="请输入密码"
              v-model="loginForm.password"></el-input></el-row>
          <el-row style="height: 15%"><el-checkbox v-model="checked"
              style="float: left; margin-left: 13%">记住密码</el-checkbox>
          </el-row>
          <el-row style="height: 25%">
            <img src="../assets/images/loginBtn.png" style="width: 75%; cursor: pointer" @click="doLogin()" />
          </el-row>
        </div>
      </el-row>
      <el-row style="height: 25%"></el-row>
    </el-col>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      loginForm: { username: "", password: "" },
      checked: false,
    };
  },
  methods: {
    doLogin: function () {
      this.$axios.login({ username: this.loginForm.username, password: this.loginForm.password }).then((res) => {
      // if (
      //   this.loginForm.username == "admin" &&
      //   this.loginForm.password == "zhmy@2022"
      // ) {
      //   this.$axios.login({ username: "sqy", password: "sqy@2023" }).then((res) => {
          //console.log(res);
          if (res.code == 200) {
            // let result = res.result;
            // var token = result.tokenInfo;
            // sessionStorage.clear();
            // sessionStorage.setItem("token", token);
            console.log(res)
            var userId = res.result.id;
            var nickName = res.result.nickName;
            var orgCode = res.result.orgCode;
            this.setUserInfo(userId, nickName, orgCode);
            this.$message({
              message: "登录成功，欢迎进入智慧牧业金融监管平台",
              type: "success",
            });
            this.$router.push("/index");
          } else {
            this.$message.error("登录失败，请检查用户名或密码是否填写正确");
          }
        });
      // } else {
      //   this.$message.error("登录失败，请检查用户名或密码是否填写正确");
      // }
    },
    setUserInfo(userId, nickName, orgCode) {
      var vm = this;
      // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
      // 如果没有勾选，储存的信息为空
      if (vm.checked) {
        localStorage.setItem("userName", vm.loginForm.username);
        localStorage.setItem("userPwd", vm.loginForm.password);
        localStorage.setItem("checked", vm.checked);
      } else {
        localStorage.clear();
      }
      localStorage.setItem("userId", userId);
      localStorage.setItem("nickName", nickName);
      localStorage.setItem("orgCode", orgCode);
    },
  },
  created: function () {
    // 在页面加载时从cookie获取登录信息
    let userName = localStorage.getItem("userName");
    let userPwd = localStorage.getItem("userPwd");

    // 如果存在赋值给表单，并且将记住密码勾选
    if (userName) {
      this.loginForm.username = userName;
      this.loginForm.password = userPwd;
      this.checked = true;
    }
  },
};
</script>
<style>
.login-wrap {
  background: url("../assets/images/login.jpg") no-repeat;
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.loginDiv {
  background: url("../assets/images/loginForm.png") no-repeat;
  width: 30%;
  height: 40%;
  position: fixed;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 4%;
  margin-left: 10%;
}
</style>

<style lang="scss" scoped>
::v-deep .el-input {
  width: 75%;
}

::v-deep .el-input__inner {
  background-color: rgba(255 255 255 / 0%);
  color: #fef0f0;
  width: 100%;
  height: 45px;
  caret-color: #44b9f0;
  border: 2px solid #1f7fa5;
  font-size: medium;
}

::v-deep .el-input__inner:hover {
  border-color: #44b9f0;
}

::v-deep .el-input__inner:focus {
  border-color: #44b9f0;
}

::v-deep .el-input--prefix .el-input__inner {
  padding-left: 45px;
}

::v-deep .el-checkbox__inner {
  background-color: rgba(255 255 255 / 0%);
  border: 1px solid #1f7fa5;
}

::v-deep .el-checkbox,
.el-checkbox-button__inner {
  color: #fef0f0d5;
}

::v-deep .el-checkbox__label {
  display: inline-block;
  padding-left: 12px;
  line-height: 30px;
  font-size: 15px;
}
</style>
