import {Get, Post, Delete} from "@/api/request";

export default {
    login: (params) => {
        return Post('login/nc', params);
    },
    getListData: (params) => {
        return Get('../../static/data.json', params);
    },
    postListData: (params) => {
        return Post('../../static/data.json', params);
    },
    deleteListData: (params) => {
        return Delete('../../static/data.json', params);
    },
    getNcInfo: (params) => {
        return Get('/nc/getNcInfo', params);
    },
    getQcInfo: (params) => {
        return Get('/nc/getQcInfo', params);
    },
    getNnInfo: (params) => {
        return Get('/nc/getNnInfo', params);
    },
    getTjInfo: (params) => {
        return Get('/nc/getTjInfo', params);
    },
    getJkList: (params) => {
        return Get('/nc/getJkList', params);
    },
    doCheck: (params) => {
        return Get('/nc/doCheck/1', params);
    },
    doMilkCheck: (params) => {
        return Get('/nc/doCheck/2', params);
    },
    getCows: (params) => {
        return Get('/nc/getCows', params);
    },
    doDsCheck: (params) => {
        return Get('/nc/getDsCheck/1', params);
    },
    doMilkDsCheck: (params) => {
        return Get('/nc/getDsCheck/2', params);
    },
    getCowsCount: (params) => {
        return Get('/nc/getCowsCount', params);
    },
    getMilk: (params) => {
        return Get('/nc/getMilk', params);
    },
    getEnsilage: (params) => {
        return Get('/nc/getEnsilage', params);
    },
    getWarning: (params) => {
        return Get('/nc/getWarning', params);
    },
    getWarningInfo: (params) => {
        return Get('/nc/getWarningInfo', params);
    },
    getPatrolRecords: (params) => {
        return Get('/nc/getPatrolRecords', params);
    },
    addInsure: (params) => {
        return Post('/insure/addInsure', params);
    },
    getInsures: (params) => {
        return Get('/insure/getInsures', params);
    },
    getInsureById: (params) => {
        return Get('/insure/getInsureById', params);
    },
    // 查询鲜奶统计数据
    getMilkStatistic: (params) => {
        return Get('/nc/getMilkStatistic', params);
    },
    // 查询青储统计数据-按季度统计
    getQCStatistic: (params) => {
        return Get('/nc/getQCStatistic', params);
    },
    // 查询牛只数量统计-近15天
    getCowCheckCount: (params) => {
        return Get('/nc/getCowCheckCount', params);
    },
    // 解除预警
    removeWarning: (params) => {
        return Post(`/nc/removeWarning`, params);
    },
    // 更新牛只阶段类型
    updatePedigreeClass: (params) => {
        return Post('/nc/updatePedigreeClass', params);
    },
    // 新增巡查记录
    addPatrol: (params) => {
        return Post('/nc/addPatrol', params);
    },
    // 查询育肥牛详情信息
    getFatteningCattle: (params) => {
        return Get('/nc/getFatteningCattle', params);
    },
    // 获取半小时内液位信息
    getLiquidCheck: (params) => {
        return Get('/ly/getLiquidCheck', params);
    },
    // 获取当前火焰温度
    getLatestFireCheck: (params) => {
        return Get('/ly/getLatestFireCheck', params);
    },
    //数据平台（首页-获取实时缴费数据）
    getIndexPayData: (params) => {
        return Post('/index/tradeAmountData', params);
    },
    //数据平台（首页-获取主数据）
    getIndexMainData: (params) => {
        return Post('/index/mainData', params);
    },
    //数据平台（首页-获取直饮水用户数据）
    getIndexDwUserData: (params) => {
        return Post('/index/dwUser', params);
    },
    //数据平台（首页-获取区域缴费数据）
    getIndexZonePaymentData: (params) => {
        return Post('/index/zonePayment', params);
    },
    //数据平台（首页-获取直饮水水量数据）
    getIndexDwWaterData: (params) => {
        return Post('/index/dwWater', params);
    },
    //数据平台（首页-加载区域下拉框数据）
    getIndexZonePickData: (params) => {
        return Post('/index/zonePick', params);
    },
    //数据平台（首页-加载区域平均缴费数据）
    getIndexZoneAvgData: (params) => {
        return Post('/index/zoneAvg', params);
    },
    //数据平台（首页-加载区域月缴费数据）
    getIndexZoneMonthData: (params) => {
        return Post('/index/zoneMonth', params);
    },
    //数据平台（首页-加载区域水量数据）
    getIndexZoneWaterData: (params) => {
        return Post('/index/zoneDayWater', params);
    },
    //数据平台（报修-加载报修统计主数据）
    getOperateMainData: (params) => {
        return Post('/index/mainOperateData', params);
    },
    //数据平台（报修-加载直饮水年月报修统计主数据）
    getIndexDwOperateData: (params) => {
        return Post('/index/dwOperateData', params);
    },
    //数据平台（水量-加载直饮水用水量主数据）
    getIndexDwWaterMainData: (params) => {
        return Post('/water/mainWater', params);
    },
    //数据平台（水量-加载用水类型统计数据）
    getIndexDwWaterTotalData: (params) => {
        return Post('/water/waterType', params);
    },
    //数据平台（水量-加载用水区域统计数据）
    getIndexZoneWater: (params) => {
        return Post('/water/waterZone', params);
    },
    //数据平台（水量-加载用水区域统计数据）
    getIndexMonthWaterData: (params) => {
        return Post('/water/waterMonth', params);
    },
    //数据平台（水量-加载用水区域统计数据）
    getIndexZMonthWaterData: (params) => {
        return Post('/water/waterZMonth', params);
    },
    //数据平台（水量-加载用水用户排名）
    getWaterUsedData: (params) => {
        return Post('/water/waterUsed', params);
    },

    //数据平台（水量-加载用水区域统计数据）
    getIndexZoneWaterAvgData: (params) => {
        return Post('/water/waterZoneAvgData', params);
    },
    //数据平台（水量-加载用水区域统计数据）
    getIndexZoneMonthWaterData: (params) => {
        return Post('/water/waterZoneMonth', params);
    },
    //数据平台（水量-加载用水主数据统计数据）
    getWaterMainData: (params) => {
        return Post('/water/waterMainData', params);
    },
    //数据平台（水量-加载用水主数据统计数据）
    getWaterMainDayData: (params) => {
        return Post('/water/waterMainDayData', params);
    },
    //数据平台（水量-加载用水主数据12个月统计数据）
    getWaterMainMonthData: (params) => {
        return Post('/water/waterMainMonthData', params);
    },
    //数据平台（缴费-获取缴费数据）
    getPayData: (params) => {
        return Post('/pay/payData', params);
    },
    //数据平台（缴费-获取区域缴费数据）
    getIndexZonePay: (params) => {
        return Post('/pay/zonePayData', params);
    },
    //数据平台（缴费-获取区域缴费数据）
    getIndexMonthPayData: (params) => {
        return Post('/pay/monthPayData', params);
    },
    getIndexZMonthPayData: (params) => {
        return Post('/pay/zMonthPayData', params);
    },
    getPayMainData: (params) => {
        return Post('/pay/payMainData', params);
    },
    getPayMainDayData: (params) => {
        return Post('/pay/payMainDayData', params);
    },
    getPayUsedData: (params) => {
        return Post('/pay/payUsedData', params);
    },
    getDeviceCountData: (params) => {
        return Post('/device/countData', params);
    },
    getElectricityData: (params) => {
        return Post('/device/electricityData', params);
    },
    getSignalData: (params) => {
        return Post('/device/signalData', params);
    },
    getZoneDeviceData: (params) => {
        return Post('/device/zoneDeviceData', params);
    },
    //数据平台（设备-获取预警数据）
    getWarningData: (params) => {
        return Post('/device/getWarningData', params);
    },
    getDeviceMainDayData: (params) => {
        return Post('/device/deviceMainDayData', params);
    },
    getWaterWarningData: (params) => {
        return Post('/device/waterWarningData', params);
    },
    getWarningTimeData: (params) => {
        return Post('/device/warningTimeData', params);
    },
    getWarningMonthData: (params) => {
        return Post('/device/warningMonthData', params);
    },
    getOrderData: (params) => {
        return Post('/operate/orderData', params);
    },
    getMonthBxData: (params) => {
        return Post('/operate/getBxData', params);
    },
    getMonthBtData: (params) => {
        return Post('/operate/getBtData', params);
    },
    getMonthXhData: (params) => {
        return Post('/operate/getXhData', params);
    },
    getMonthJcData: (params) => {
        return Post('/operate/getJcData', params);
    },
    getZoneOrder: (params) => {
        return Post('/operate/zoneOrder', params);
    },
    getOrderMainData: (params) => {
        return Post('/operate/mainOrderData', params);
    },
    getOrderMainDayData: (params) => {
        return Post('/operate/orderMainDayData', params);
    },
    getIndexZoneMonthOrderData: (params) => {
        return Post('/operate/zoneMonthOrderData', params);
    },
    getOrderZoneMonth: (params) => {
        return Post('/operate/orderZoneMonth', params);
    },

    getOrderDetailData: (params) => {
        return Post('/operate/orderDetailData', params);
    },
    
    // 获取公司信息
	getCarClass: (params) => {
		return Get('/car/manager/getCarClass', params);
	},
    // 获取车辆预警异常列表
	getExceptionCarList: (params) => {
		return Get('/car/manager/getExceptionCarList', params);
	},
}
