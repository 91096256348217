import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    // {
    //     path: '/index',
    //     name: 'index',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/IndexView.vue')
    // },
    {
        path: '/lyWater',
        component: () => import(/* webpackChunkName: "about" */ '../views/lyWater/WaterIndex.vue')
    },
    {
        path: '/lyFire',
        component: () => import(/* webpackChunkName: "about" */ '../views/lyFire/FireIndex.vue')
    },
    {
        path: '/yfn',
        component: () => import(/* webpackChunkName: "about" */ '../views/yfn/IndexView.vue')
    },
    {
        path: '/yfnCowInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/yfn/yfnCowInfo.vue')
    },
    {
        path: '/index',
        component: () => import(/* webpackChunkName: "about" */ '../views/index/IndexView.vue')
    },
    {
        path: '/new',
        name: 'new',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/NewView.vue')
    },
    {
        path: '/count',
        name: 'count',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CountView.vue')
    },
    {
        path: '/cameras',
        name: 'cameras',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CamerasView.vue')
    },
    {
        path: '/cows-count',
        name: 'cows-count',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CowsCountView.vue')
    },
    {
        path: '/milk',
        name: 'milk',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/MilkView.vue')
    },
    {
        path: '/ensilage',
        name: 'ensilage',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/EnsilageView.vue')
    },
    {
        path: '/warning',
        name: 'warning',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/WarningView.vue')
    },
    {
        path: '/record',
        name: 'record',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PatrolRecordView.vue')
    },
    {
        path: '/insure',
        name: 'insure',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/InsureView.vue')
    },
    {
        // 新增巡查记录
        path: '/addPatrol',
        component: () => import(/* webpackChunkName: "about" */ '../views/PatrolAddView.vue')
    },
    {
        // 更新牛只阶段类型
        path: '/updatePedigreeClass',
        component: () => import(/* webpackChunkName: "about" */ '../views/UpdatePedigreeClassView.vue')
    },
    {
        path: '/cowsCompare',
        component: () => import(/* webpackChunkName: "about" */ '../views/CowsCompare.vue')
    },
    {
        path: '/cowsCompareResult',
        component: () => import(/* webpackChunkName: "about" */ '../views/CowsCompareResult.vue')
    },
    {
        path: '/cowInfo',
        name: 'cowInfo',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CowInfoView.vue')
    },
    {
        path: '/insureHome',
        name: 'insureHome',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/InsureHomeView.vue')
    },
    {
        path: '/msDevice',
        name: 'msDevice',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/WaterMSDevice.vue')
    },
    {
        path: '/msIndex',
        name: 'msIndex',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/WaterMSIndex.vue')
    },
    {
        path: '/msOperate',
        name: 'msOperate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/WaterMSOperate.vue')
    },
    {
        path: '/msPay',
        name: 'msPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/WaterMSPay.vue')
    },
    {
        path: '/msTotal',
        name: 'msTotal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/WaterMSWaterTotal.vue')
    },
    {
        path: '/car',
        name: 'car',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CarIndexView.vue')
    },
    {
        path: '/carScreen',
        name: 'carScreen',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/carSuperviseScreen/carScreen.vue')
    },
    //煤炭大屏
    // {
    //     path: '/coalIndex',
    //     name: 'coalIndex',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/coal/CoalIndex.vue')
    // }
]

const router = new VueRouter({
    routes
})

export default router
